<template>
  <q-page>
    <div class="be-main-lr">
      <div v-show="$q.platform.is.mobile !== true && showSecondMenu" class="be-desktop-second-menu">
        <div class="row q-col-gutter-md q-mt-xs be-desktop-container">
          <div v-if="allowOrdering" class="col-auto" style="width: 310px">
            <q-btn-group spread style="height: 45px">
              <q-btn
                v-if="settingsStore.shop_status.someone_has_delivery"
                :class="{
                  'be-btn-xs': true,
                  'be-radius-20': true,
                  'be-toggle-active': cartStore.delivery_type === 'delivery',
                  'be-toggle-inactive': cartStore.delivery_type !== 'delivery'
                }"
                icon="app:delivery"
                :label="$t('delivery')"
                @click="openGeolocation('delivery')"
              />
              <q-btn
                v-if="settingsStore.shop_status.someone_has_pickup"
                :class="{
                  'be-btn-xs': true,
                  'be-radius-20': true,
                  'be-toggle-active': cartStore.delivery_type === 'pickup',
                  'be-toggle-inactive': cartStore.delivery_type !== 'pickup'
                }"
                icon="app:pick-up"
                :label="$t('pickup')"
                @click="openGeolocation('pickup')"
              />
            </q-btn-group>
          </div>
          <div v-if="allowOrdering" class="col-auto" style="width: 310px">
            <q-select
              v-model="pickupDeliveryId"
              outlined
              emit-value
              map-options
              options-dense
              dense
              class="be-icon-12 be-text-sm"
              :options="pickupDeliveryItems"
              option-label="name"
              option-value="id"
              @update:model-value="updatePickupDelivery"
            >
              <template #prepend>
                <q-icon name="app:location" />
              </template>
            </q-select>
          </div>
          <div class="col">
            <q-input
              v-model="search"
              clearable
              type="text"
              outlined
              dense
              debounce="1000"
              :placeholder="$t('search-product')"
              @update:model-value="searchProduct"
            >
              <template #prepend>
                <q-icon name="app:search" />
              </template>
            </q-input>
          </div>
          <div v-if="settingsStore.has_allergens" class="col-auto">
            <q-btn icon="app:filter-icon" class="be-btn-l be-icon-20 be-icon-accent" @click="openFilters = true" />
          </div>
          <div v-if="allowOrdering" class="col-auto">
            <q-btn
              icon="app:cart"
              class="be-btn-l be-icon-20 bg-black img-no-filter"
              :aria-label="$t('common.my-order')"
              @click="showCart = true"
            >
              <q-badge
                v-if="cartStore.products.length > 0"
                data-cy="cart-products-number"
                color="accent"
                floating
                style="top: 4px; right: 5px"
              >
                {{ cartStore.products.length }}
              </q-badge>
            </q-btn>
          </div>
        </div>
      </div>
      <div
        v-if="$q.platform.is.mobile !== true || settingsStore.banners.length >= 1"
        :class="$q.platform.is.mobile !== true ? 'be-banner-desktop' : 'be-banner'"
        :style="settingsStore.banners.length >= 1 ? 'height: auto;' : ''"
      >
        <template v-if="settingsStore.banners.length < 1 && settingsStore.website.background !== null">
          <picture>
            <source
              :srcset="$assetsUrl() + settingsStore.website.background.split('.').shift() + '.webp'"
              type="image/webp"
            />
            <img
              :src="$assetsUrl() + settingsStore.website.background"
              fit="cover"
              alt="food background"
              :height="$q.platform.is.mobile !== true ? '436' : '140'"
            />
          </picture>
        </template>
        <template v-else>
          <q-carousel
            v-if="settingsStore.banners.length > 0 && settingsStore.banners[0].images.length > 1"
            v-model="bannerVmodel"
            arrows
            :autoplay="bannerAutoplay"
            infinite
            @mouseenter="bannerAutoplay = false"
            @mouseleave="bannerAutoplay = true"
          >
            <template v-for="(banner, indexBanner) in settingsStore.banners" :key="'banner' + indexBanner">
              <q-carousel-slide
                v-for="(image, indexImage) in banner.images"
                :key="'banner' + indexBanner + 'img' + indexImage"
                :name="'banner' + indexBanner + 'img' + indexImage"
                :img-src="
                  $assetsUrl() + (settingsStore.accept_webp ? image.image.split('.').shift() + '.webp' : image.image)
                "
              >
                <a :href="image.url">
                  <div :style="{ width: '100%', height: '100%' }"></div>
                </a>
              </q-carousel-slide>
            </template>
          </q-carousel>
          <a v-else-if="settingsStore.banners.length > 0" :href="settingsStore.banners[0].images[0].url">
            <q-img
              :src="$assetsUrl() + settingsStore.banners[0].images[0].image"
              fit="cover"
              alt="food background"
              :height="$q.platform.is.mobile !== true ? '436' : '140'"
            >
            </q-img>
            <div :style="{ width: '100%', height: '100%' }" />
          </a>
        </template>
        <div
          v-if="
            cartStore.delivery_type !== 'table' &&
            settingsStore.shop_status.message !== null &&
            $q.platform.is.mobile !== true
          "
          class="be-shop-closed"
        >
          <div style="display: inline-block">
            <q-icon name="app:shop-closed" class="be-icon-26" />
          </div>
          <div style="display: inline-block; vertical-align: top">
            <span class="text" icon="app:shop-closed" v-html="settingsStore.shop_status.message"></span>
          </div>
        </div>
        <div
          v-if="$q.platform.is.mobile !== true"
          class="be-banner-desktop-content"
          :style="settingsStore.banners.length >= 1 ? 'left: 0px;' : ''"
        >
          <div v-if="settingsStore.banners.length < 1" class="text-white" style="max-width: 650px">
            <div class="be-text-40 be-text-bold" v-html="$t('homepage.what-do-you-want-to-eat')"></div>
            <div class="be-text-24" v-html="$t('homepage.choose-delivery-type-let-discover')"></div>
          </div>
          <div
            class="row q-col-gutter-md q-mt-xs"
            :style="settingsStore.banners.length >= 1 ? 'margin: auto' : ''"
            style="max-width: 800px"
          >
            <div v-if="allowOrdering" class="col" :class="{ disabled: cartStore.group_order }">
              <q-btn-group spread style="height: 45px">
                <q-btn
                  v-if="settingsStore.shop_status.someone_has_delivery"
                  :class="{
                    'be-btn-xs': true,
                    'be-radius-20': true,
                    'be-toggle-active': cartStore.delivery_type === 'delivery',
                    'be-toggle-inactive': cartStore.delivery_type !== 'delivery'
                  }"
                  icon="app:delivery"
                  :label="$t('delivery')"
                  @click="openGeolocation('delivery')"
                />
                <q-btn
                  v-if="settingsStore.shop_status.someone_has_pickup"
                  :class="{
                    'be-btn-xs': true,
                    'be-radius-20': true,
                    'be-toggle-active': cartStore.delivery_type === 'pickup',
                    'be-toggle-inactive': cartStore.delivery_type !== 'pickup'
                  }"
                  icon="app:pick-up"
                  :label="$t('pickup')"
                  @click="openGeolocation('pickup')"
                />
              </q-btn-group>
            </div>
            <div v-if="allowOrdering" class="col" :class="{ disabled: cartStore.group_order }">
              <q-select
                v-model="pickupDeliveryId"
                outlined
                emit-value
                map-options
                options-dense
                dense
                class="be-icon-12 be-text-sm"
                :options="pickupDeliveryItems"
                option-label="name"
                option-value="id"
                @update:model-value="updatePickupDelivery"
              >
                <template #option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section
                      style="display: inline"
                      @click="scope.opt.delivery && !scope.opt.pickup ? openGeolocation('delivery') : ''"
                    >
                      <q-item-label style="display: inline; margin-right: 5px">{{ scope.opt.name }}</q-item-label>
                      <div style="display: inline">
                        <div v-if="scope.opt.pickup && scope.opt.delivery" style="display: inline">
                          <q-icon name="app:delivery" style="margin-right: 5px" />
                          <q-icon name="app:pick-up" />
                        </div>
                        <q-icon v-else-if="scope.opt.pickup" name="app:pick-up" />
                        <q-icon v-else-if="scope.opt.delivery" name="app:delivery" />
                      </div>
                    </q-item-section>
                  </q-item>
                </template>
                <template #prepend>
                  <q-icon name="app:location" />
                </template>
              </q-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="be-main-lr">
      <div
        v-if="
          cartStore.delivery_type !== 'table' &&
          settingsStore.shop_status.message !== null &&
          $q.platform.is.mobile === true
        "
        class="be-shop-closed-mobile"
      >
        <div style="display: inline-block; margin-right: 10px">
          <q-icon name="app:shop-closed" class="be-icon-26" />
        </div>
        <div style="display: inline-block">
          <span class="text" icon="app:shop-closed" v-html="settingsStore.shop_status.message"></span>
        </div>
      </div>
      <div
        v-if="cartStore.table_number !== null && (allowOrdering || callWaiter)"
        class="be-accent-border text-center q-mb-md"
        style="font-size: 20px; font-weight: 500px; padding: 12px 0"
      >
        <q-icon name="app:table-ordering" class="be-icon-52 inline-block be-icon-accent" style="vertical-align: top" />
        <div v-if="allowOrdering" class="inline-block q-mr-sm q-ml-sm" style="margin-top: 12px">
          {{ $t('table-ordering.title') }}:
        </div>
        <div v-else class="inline-block q-mr-sm q-ml-sm" style="margin-top: 12px">
          {{ $t('table-ordering.table-number', { number: '' }) }}
        </div>
        <div class="inline-block text-accent">
          {{ cartStore.table_number }}
        </div>
      </div>
    </div>
    <div class="row q-col-gutter-md be-main-lr">
      <div class="col">
        <q-input
          v-model="search"
          clearable
          type="text"
          outlined
          dense
          debounce="1000"
          :placeholder="$t('search-product')"
          @update:model-value="searchProduct"
        >
          <template #prepend>
            <q-icon name="app:search" />
          </template>
        </q-input>
      </div>
      <div v-if="settingsStore.has_allergens" class="col-auto">
        <q-btn
          icon="app:filter-icon"
          class="be-btn-l be-icon-20 be-icon-accent"
          :aria-label="$t('filters.select-allergens')"
          @click="openFilters = true"
        />
      </div>
      <div v-if="$q.platform.is.mobile !== true && allowOrdering" class="col-auto">
        <q-btn
          icon="app:cart"
          class="be-btn-l bg-black text-white be-icon-18 q-mb-sm img-no-filter btn-around btn-around"
          style="width: 310px"
          :label="$t('common.my-order')"
          align="around"
          data-cy="cart-button"
          @click="showCart = !showCart"
        >
          <q-badge
            v-if="cartStore.products.length > 0"
            color="accent"
            floating
            style="left: 24px; top: 5px; right: auto"
            data-cy="cart-products-number"
          >
            {{ cartStore.products.length }}
          </q-badge>
        </q-btn>
      </div>
    </div>
    <div v-if="search === '' || search === null">
      <div
        v-if="$q.platform.is.mobile && hideDeliveryPickupOptions === false && allowOrdering"
        class="row q-col-gutter-md q-mt-xs be-main-lr"
      >
        <div class="col" :class="{ disabled: cartStore.group_order }">
          <q-btn-group spread style="height: 45px">
            <q-btn
              v-if="settingsStore.shop_status.someone_has_delivery"
              :class="{
                'be-btn-xs': true,
                'be-radius-20': true,
                'be-toggle-active': cartStore.delivery_type === 'delivery',
                'be-toggle-inactive': cartStore.delivery_type !== 'delivery'
              }"
              icon="app:delivery"
              :label="$t('delivery')"
              @click="openGeolocation('delivery')"
            />
            <q-btn
              v-if="settingsStore.shop_status.someone_has_pickup"
              :class="{
                'be-btn-xs': true,
                'be-radius-20': true,
                'be-toggle-active': cartStore.delivery_type === 'pickup',
                'be-toggle-inactive': cartStore.delivery_type !== 'pickup'
              }"
              icon="app:pick-up"
              :label="$t('pickup')"
              @click="openGeolocation('pickup')"
            />
          </q-btn-group>
        </div>
        <div class="col" :class="{ disabled: cartStore.group_order }">
          <q-select
            v-model="pickupDeliveryId"
            outlined
            emit-value
            map-options
            options-dense
            dense
            class="be-icon-12 be-text-sm"
            :options="pickupDeliveryItems"
            option-label="name"
            option-value="id"
            @update:model-value="updatePickupDelivery"
          >
            <template #prepend>
              <q-icon name="app:location" />
            </template>
          </q-select>
        </div>
      </div>
      <div v-if="menusStore.items.length > 1" class="be-main-lr be-text-menus">
        <q-select
          v-model="menusStore.selected"
          :options="menusStore.items"
          option-label="name"
          @update:model-value="navigateToMenu"
        />
      </div>
      <h3 v-else style="margin-bottom: 0" class="be-main-lr" v-html="$t('categories')"></h3>
      <q-tabs class="be-categories items-start" content-class="items-start" indicator-color="transparent">
        <template v-for="(category, cindex) in categories" :key="'category' + cindex">
          <q-route-tab
            :icon="category.photo !== null ? 'img:' + $assetsUrl() + category.photo : 'app:fast-food'"
            :ripple="false"
            no-caps
            :class="{
              'self-start': true,
              'q-tab--active': selectedCategory.id == category.id,
              'q-router-link--exact-active': selectedCategory.id == category.id
            }"
            :label="category.name"
            :to="'/' + category.url + '/' + category.id + '/' + menusStore.selected.id"
          />
        </template>
      </q-tabs>
      <div v-if="lastOrder !== null && lastOrder.products.length > 0" class="be-last-order">
        <h3 style="margin-bottom: 0; margin-top: 0" class="be-main-lr" v-html="$t('your-last-order')"></h3>
        <div class="row q-col-gutter-md be-main-lr q-mb-md">
          <div class="col-md-6 col-sm-12 col-xs12">
            <order-card
              :order-item="lastOrder"
              @view-order="$router.push(`/account/orders?order_id=${lastOrder.id}`)"
              @repeat-order="repeatOrder(lastOrder.id)"
            ></order-card>
          </div>
        </div>
      </div>
      <div v-if="recommendedProducts.length > 0">
        <h3 class="be-main-lr" style="margin-top: 0" v-html="$t('recommended-products')"></h3>
        <div
          class="row q-col-gutter-md"
          style="flex-flow: nowrap; padding-left: 20px; padding-right: 20px; overflow-y: auto; padding-bottom: 30px"
        >
          <div
            v-for="(product, index) in recommendedProducts"
            :key="'recommended-product' + index"
            class="col-md-4 col-sm-7 col-xs-7"
          >
            <q-card class="be-product-recommended-card" @click="orderProduct(product.id, index)">
              <picture v-if="product.photo !== null">
                <source
                  :srcset="$assetsUrl() + product.photo.replace('/res1000.', '/res250.').split('.')[0] + '.webp'"
                  type="image/webp"
                />
                <img
                  :src="$assetsUrl() + product.photo.replace('/res1000.', '/res250.')"
                  :alt="product.name"
                  fit="cover"
                />
              </picture>
              <img
                v-else
                :src="$assetsUrl() + 'images/no-photo-food.jpg'"
                alt="no photo"
                loading="lazy"
                style="opacity: 0.4"
              />
              <q-card-section>
                <div class="be-product-content">
                  <div class="be-product-name" v-html="product.name" />
                  <div
                    class="be-product-description"
                    v-html="
                      product.description === null || product.description.length < 100
                        ? product.description
                        : product.description.substring(0, 100) + '...'
                    "
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <div class="be-product-price">
                      <div v-if="product.out_of_stock == 0">
                        <span v-if="product.old_price" class="inline q-mr-sm be-old-price">
                          {{ $formatCurrency(Number(product.old_price), currency) }}
                        </span>
                        <span class="inline">
                          {{ Number(product.price) > 0 ? $formatCurrency(Number(product.price), currency) : '' }}
                          {{ product.unit !== null && product.unit !== '' ? ' /' + product.unit : '' }}
                        </span>
                      </div>
                      <div v-else v-html="$t('products.unavailable')" />
                    </div>
                  </div>
                  <div v-if="allowOrdering" class="col-auto text-right">
                    <q-btn
                      class="be-btn-s bg-black be-icon-white img-no-filter"
                      icon="app:cart"
                      :aria-label="$t('order-product.add-product-to-order')"
                    />
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
      <h3 class="be-main-lr" style="margin-top: 0; margin-bottom: 0" v-html="$t('all-products')"></h3>
    </div>
    <div v-touch-swipe.mouse.horizontal="handleCategorySwipe" style="padding-bottom: 30px; padding-top: 20px">
      <q-infinite-scroll
        ref="infiniteScrollProducts"
        class="row q-col-gutter-md be-main-lr"
        :disable="$q.platform.is.mobile !== true"
        @load="loadNextCategory"
      >
        <template v-for="(product, index) in products" :key="'product' + index">
          <div v-if="product.id === 0" class="col-12">
            <h3 style="margin-bottom: 0" v-html="product.name"></h3>
          </div>
          <div v-else class="col-xs-6 col-sm-6 col-md-3">
            <q-card class="be-product-list-card" data-cy="product-list-card" @click="orderProduct(product.id, index)">
              <picture v-if="product.photo !== null">
                <source
                  :srcset="$assetsUrl() + product.photo.replace('/res1000.', '/res250.').split('.')[0] + '.webp'"
                  type="image/webp"
                />
                <img :src="$assetsUrl() + product.photo.replace('/res1000.', '/res250.')" :alt="product.name" />
              </picture>
              <img
                v-else
                :src="$assetsUrl() + 'images/no-photo-food.jpg'"
                alt="no photo"
                loading="lazy"
                style="opacity: 0.4"
              />
              <q-card-section>
                <div class="be-product-content">
                  <div class="be-product-name">{{ product.name }}</div>
                  <div class="be-product-description">
                    {{
                      product.description === null || product.description.length < 100
                        ? product.description
                        : product.description.substring(0, 100) + '...'
                    }}
                  </div>
                  <div class="gradientback"></div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="be-product-price">
                      <div v-if="product.out_of_stock == 0">
                        <span v-if="product.old_price" class="inline q-mr-sm be-old-price">
                          {{ $formatCurrency(Number(product.old_price), currency) }}
                        </span>
                        <span class="inline">
                          {{ Number(product.price) > 0 ? $formatCurrency(Number(product.price), currency) : '' }}
                          {{ product.unit !== null && product.unit !== '' ? ' /' + product.unit : '' }}
                        </span>
                      </div>
                      <div v-else v-html="$t('products.unavailable')" />
                    </div>
                  </div>
                  <div v-if="allowOrdering" class="col-auto text-right">
                    <q-btn
                      class="be-btn-xs bg-black be-icon-white img-no-filter"
                      icon="app:cart"
                      :aria-label="$t('order-product.add-product-to-order')"
                    />
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </template>

        <template #loading>
          <div class="text-center">
            <q-spinner-dots color="primary" size="40px" />
          </div>
        </template>
      </q-infinite-scroll>
      <!-- Add back to top button -->
      <q-btn
        v-if="showBackToTop"
        class="bg-white fixed-bottom-right be-btn-l q-mr-sm"
        icon="app:drop-down"
        style="bottom: 85px; transform: rotate(180deg); z-index: 10000"
        data-cy="back-to-top"
        @click="backToTop"
      />
    </div>

    <order-product
      v-if="productId !== null"
      :product-id="productId"
      :previous-product="previousProduct"
      :next-product="nextProduct"
      :current-product="currentProductData"
      :allow-ordering="allowOrdering"
      @order-product-success="orderProductSuccess"
      @product-swipe-left="showPreviousProduct"
      @product-swipe-right="showNextProduct"
      @show-geolocation="orderProductShowGeolocation"
      @close-pop-up="closeProductDialog"
    />

    <search-filter v-if="openFilters" @close-pop-up="openFilters = false" @update-filters="updateFilters" />

    <cart v-if="showCart" @close-pop-up="showCart = false" />

    <geolocation
      v-if="showGeolocationDialog"
      :show-choose-delivery-method-text="showChooseDeliveryMethodText"
      :tab="geolocationDeliveryType"
      @close-pop-up="showGeolocationDialog = false"
      @geolocation-success="updateGeolocation"
    />
    <delivery-address
      v-if="showDeliveryAddress"
      :address="deliveryAddressData"
      @close-pop-up="closeAddDeliveryAddress"
      @save-address="addressAddedUpdated"
    />

    <div v-if="$route.path !== '/'" itemscope itemtype="https://schema.org/ItemList">
      <meta itemprop="name" :content="selectedCategory.name" />
      <meta v-for="(product, index) in products" :key="index" itemprop="itemListElement" :content="product.name" />
    </div>
    <div v-else itemscope itemtype="https://schema.org/Restaurant">
      <meta itemprop="name" :content="settingsStore.website.name" />
      <meta itemprop="telephone" :content="settingsStore.website.phone" />
      <meta
        itemprop="image"
        :content="settingsStore.website.logo !== null ? `${$assetsUrl()}${settingsStore.website.logo}` : ''"
      />
      <meta
        itemprop="logo"
        :content="settingsStore.website.logo !== null ? `${$assetsUrl()}${settingsStore.website.logo}` : ''"
      />
      <meta itemprop="url" :content="settingsStore.website.url" />
      <meta itemprop="menu" :content="settingsStore.website.url" />
    </div>
  </q-page>
</template>

<script>
import { createMetaMixin, Cookies } from 'quasar'
import OrderProduct from '../components/OrderProduct.vue'
import Geolocation from '../components/Geolocation.vue'
import DeliveryAddress from '../components/DeliveryAddress.vue'
import SearchFilter from '../components/SearchFilter.vue'
import Cart from '../components/Cart.vue'
import OrderCard from '../components/OrderCard.vue'
import classifyPoint from 'robust-point-in-polygon'

import { mapState, mapActions, mapStores } from 'pinia'
import { useUserStore } from 'stores/user'
import { useMenusStore } from 'stores/menus'
import { useCategoriesStore } from 'stores/categories'
import { useProductsStore } from 'stores/products'
import { useSettingsStore } from 'stores/settings'
import { useCartStore } from 'stores/cart'

export default {
  name: 'ProductList',
  components: {
    'order-product': OrderProduct,
    geolocation: Geolocation,
    'search-filter': SearchFilter,
    cart: Cart,
    'order-card': OrderCard,
    'delivery-address': DeliveryAddress
  },
  mixins: [
    createMetaMixin(function mymeta() {
      return {
        title:
          this.selectedCategory.seo_title !== null
            ? this.selectedCategory.seo_title
            : `${this.selectedCategory.name} - ${this.settingsStore.website.name}`,
        meta: {
          description: {
            name: 'description',
            content:
              this.selectedCategory.seo_description !== null
                ? this.selectedCategory.seo_description
                : this.selectedCategory.name
          }
        }
      }
    })
  ],
  beforeRouteUpdate(to, from, next) {
    if (this.productId === null) {
      next()
    } else {
      this.closeProductDialog()
      next(false)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.productId === null) {
      next()
    } else {
      this.closeProductDialog()
      next(false)
    }
  },
  data() {
    return {
      currentUrl: null,
      bannerAutoplay: true,
      bannerVmodel: 'banner0img0',
      productId: null,
      filters: {
        allergens: []
      },
      openFilters: false,
      search: '',
      searchedProducts: [],
      showGeolocationDialog: false,
      geolocationDeliveryType: null,
      storeName: '',
      viewIndex: null,
      pickupDeliveryItems: [],
      pickupDeliveryId: null,
      previousProduct: {
        id: null,
        photo: null
      },
      nextProduct: {
        id: null,
        photo: null
      },
      cookieOptions: {
        expires: 365,
        path: '/',
        sameSite: 'Strict',
        secure: process.env.NODE_ENV !== 'development'
      },
      showSecondMenu: false,
      showCart: false,
      showGroupOrder: false,
      lastOrder: null,
      currentProductData: null,
      showChooseDeliveryMethodText: false,
      showDeliveryAddress: false,
      deliveryAddressData: null,
      hideDeliveryPickupOptions: false,
      allowOrdering: true,
      callWaiter: false,
      showBackToTop: false
    }
  },
  preFetch({ store, currentRoute, ssrContext }) {
    const storeMenus = useMenusStore(store)
    const storeCategories = useCategoriesStore(store)
    const storeProducts = useProductsStore(store)
    return new Promise(resolve => {
      let menuId = null
      if (typeof currentRoute.params.menuId !== 'undefined' && isNaN(currentRoute.params.menuId) === false) {
        menuId = Number(currentRoute.params.menuId)
      } else if (storeMenus.items.length > 0) {
        menuId = storeMenus.items[0].id
      }
      let categoryId = null
      if (typeof currentRoute.params.categoryId !== 'undefined' && isNaN(currentRoute.params.categoryId) === false) {
        categoryId = Number(currentRoute.params.categoryId)
      } else if (storeCategories.items.length > 0) {
        categoryId = storeCategories.items[0].id
      }
      const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
      let translateLanguage = null
      if (cookies.has('translate_language') && cookies.get('translate_language').length === 5) {
        translateLanguage = cookies.get('translate_language')
      }
      if (storeMenus.selected.id !== menuId) {
        const selectedMenu = storeMenus.items.find(({ id }) => id === menuId)
        if (typeof selectedMenu !== 'undefined') {
          storeMenus.setSelected(selectedMenu)
          storeCategories.fetch({ menuId: selectedMenu.id, translateLanguage }).then(() => {
            if (storeCategories.items.length > 0) {
              const selectedCategory = storeCategories.items[0]
              storeCategories.setSelected(selectedCategory)
              storeProducts
                .fetch({
                  categoryId: selectedCategory.id,
                  menuId: selectedMenu.id,
                  translateLanguage
                })
                .then(() => {
                  resolve()
                })
            } else {
              storeProducts.fetch({ categoryId: '', menuId: '', translateLanguage }).then(() => {
                resolve()
              })
            }
          })
        } else {
          resolve()
        }
      } else if (storeCategories.selected.id !== categoryId) {
        let selectedCategory = storeCategories.items.find(({ id }) => id === categoryId)
        if (typeof selectedCategory === 'undefined') {
          let found = false
          let i = 0
          selectedCategory = null
          const searchedId = categoryId
          const categories = storeCategories.items
          while (found === false && i < categories.length) {
            if (categories[i].id === searchedId) {
              selectedCategory = categories[i]
              found = true
            }
            i += 1
          }
        }
        storeCategories.setSelected(selectedCategory)
        storeProducts
          .fetch({ categoryId: selectedCategory.id, menuId: storeMenus.selected.id, translateLanguage })
          .then(() => {
            resolve()
          })
      } else {
        storeProducts
          .fetch({
            categoryId: storeCategories.selected.id,
            menuId: storeMenus.selected.id,
            translateLanguage
          })
          .then(() => {
            resolve()
          })
      }
    })
  },
  computed: {
    ...mapStores(useSettingsStore, useProductsStore, useCartStore, useMenusStore),
    ...mapState(useUserStore, {
      userId: 'id',
      userName: 'name',
      userLogged: 'isLogged',
      userDeliveryAddresses: 'delivery_addresses'
    }),
    ...mapState(useCategoriesStore, {
      categories: 'items',
      selectedCategory: 'selected'
    }),
    products() {
      if (this.search !== '' && this.search !== null) {
        return this.searchedProducts
      }
      return this.productsStore.items
    },
    recommendedProducts() {
      return this.productsStore.items.filter(item => item.recommended == 1)
    },
    currency() {
      return this.settingsStore.localization.currency
    }
  },
  watch: {
    $route() {
      this.currentUrl = this.$route.href
      this.search = ''
      this.trackView()
      this.getBanners()
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollHandler)
  },
  mounted() {
    // TODO remove this after we fix the bug with the null in products
    let products = []
    const currentNoProducts = this.cartStore.products.length
    Object.values(this.cartStore.products).forEach(val => {
      if (val !== null) {
        products.push(val)
      }
    })
    if (products.length !== currentNoProducts) {
      this.cartStore.products = products
    }

    this.currentUrl = this.$route.href
    if (process.env.BOOSTEAT_MODE === 'table-ordering') {
      this.hideDeliveryPickupOptions = true
      this.cartStore.delivery_type = 'table'
      if (typeof this.$route.query.available_actions !== 'undefined') {
        switch (this.$route.query.available_actions) {
          case 'order':
            this.allowOrdering = true
            break
          case 'message_to_waiter':
            this.callWaiter = true
            this.allowOrdering = false
            break
          default:
            this.allowOrdering = false
            break
        }
      } else {
        if (this.$q.localStorage.has('allow_ordering')) {
          this.allowOrdering = this.$q.localStorage.getItem('allow_ordering') !== 'no'
        } else {
          this.allowOrdering = false
          this.$q.localStorage.set('allow_ordering', 'no')
        }
        if (this.$q.localStorage.has('message_to_waiter')) {
          this.callWaiter = this.$q.localStorage.getItem('message_to_waiter') !== 'no'
        } else {
          this.callWaiter = false
          this.$q.localStorage.set('message_to_waiter', 'no')
        }
      }
    } else {
      if (this.cartStore.delivery_type === 'table') {
        this.cartStore.delivery_type = 'pickup'
      }
      this.allowOrdering = this.settingsStore.website.allow_ordering
    }
    if (
      this.userLogged === false &&
      this.$q.localStorage.has('be_know_delivery_pickup') === false &&
      this.cartStore.delivery_type !== 'table' &&
      this.settingsStore.auto_open_geolocation
    ) {
      this.showChooseDeliveryMethodText = true
      this.openGeolocation('pickup')
    }
    if (this.$route.name === 'category-page-with-product' && typeof this.$route.params.productId !== 'undefined') {
      this.orderProduct(Number(this.$route.params.productId), 0)
    }

    this.trackView()
    if (this.$q.localStorage.has('search_filters')) {
      this.filters = this.$q.localStorage.getItem('search_filters')
    }
    this.updatePickupDeliveryOptions()
    if (this.userLogged) {
      this.$axios
        .get(`public/account/orders?page=1&rowsPerPage=1&sortBy=id&descending=true&with=products`)
        .then(response => {
          if (response.data.orders.total > 1) {
            this.lastOrder = response.data.orders.data[0]
          }
        })
        .catch(() => {
          this.$q.loading.hide()
        })
    }
    if (this.$q.platform.is.mobile !== true) {
      window.addEventListener('scroll', this.scrollHandler)
    }
  },
  methods: {
    ...mapActions(useMenusStore, {
      menusFetch: 'fetch',
      menusSelected: 'setSelected'
    }),
    ...mapActions(useCategoriesStore, {
      categoriesFetch: 'fetch',
      categoriesSelected: 'setSelected'
    }),
    ...mapActions(useUserStore, {
      setUserDeliveryAddress: 'setDeliveryAddress'
    }),
    scrollHandler() {
      this.showSecondMenu = window.scrollY > 350
    },
    showPreviousProduct(productId, currentProduct) {
      if (this.viewIndex === 0) {
        this.viewIndex = this.products.length - 1
      } else {
        this.viewIndex -= 1
      }
      this.productId = productId
      this.nextProduct = currentProduct
      // set previous product
      let viewIndex = this.viewIndex
      if (this.viewIndex === 0) {
        viewIndex = this.products.length - 1
      } else {
        viewIndex -= 1
      }
      this.previousProduct = this.products[viewIndex]
    },
    showNextProduct(productId, currentProduct) {
      if (this.viewIndex === this.products.length - 1) {
        this.viewIndex = 0
      } else {
        this.viewIndex += 1
      }
      this.productId = productId
      this.previousProduct = currentProduct
      // set next product
      let viewIndex = this.viewIndex
      if (this.viewIndex === this.products.length - 1) {
        viewIndex = 0
      } else {
        viewIndex += 1
      }
      this.nextProduct = this.products[viewIndex]
    },
    orderProductSuccess() {
      this.closeProductDialog()
      this.$q.notify({ message: this.$t('order-product.product-added-success'), position: 'top', color: 'positive' })
    },
    orderProduct(productId, index) {
      if (this.currentProductData !== null && productId !== this.currentProductData.id) {
        this.currentProductData = null
      }
      this.productId = productId
      this.viewIndex = index

      // set previous product
      let viewIndex = this.viewIndex
      if (this.viewIndex === 0) {
        viewIndex = this.products.length - 1
      } else {
        viewIndex -= 1
      }
      this.previousProduct = this.products[viewIndex]
      // set next product
      viewIndex = this.viewIndex
      if (this.viewIndex === this.products.length - 1) {
        viewIndex = 0
      } else {
        viewIndex += 1
      }
      this.nextProduct = this.products[viewIndex]
    },
    searchProduct() {
      if (this.search === '' || this.search === null) {
        this.searchedProducts = []
      } else {
        let filterAllergens = ''
        if (this.filters.allergens.length > 0) {
          filterAllergens = `&allergens=${this.filters.allergens.join(',')}`
        }
        let filtersQuery = ''
        if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
          filtersQuery += `&translate_language=${this.$q.cookies.get('translate_language')}`
        }
        this.$axios.get(`public/products?search=${this.search}${filterAllergens}${filtersQuery}`).then(response => {
          this.searchedProducts = response.data.products
        })
        if (this.$q.localStorage.has('be_cookie_consent')) {
          try {
            const acceptedCookies = this.$q.localStorage.getItem('be_cookie_consent')
            const { trackers } = this.settingsStore
            Object.keys(trackers).forEach(type => {
              if (type === 'gtag' && acceptedCookies.statistically) {
                this.$gtag.event('search', {
                  search_term: this.search
                })
              }
            })
            if (acceptedCookies.performance && this.$amplitude !== null) {
              this.$amplitude.track({
                event_type: '[BE] Search products',
                event_properties: {
                  searchTerm: this.search,
                  companyId: this.settingsStore.website.company_id,
                  companyName: this.settingsStore.website.name
                }
              })
            }
          } catch (err) {
            // to do
          }
        }
      }
    },
    getBanners() {
      if (this.settingsStore.has_banners) {
        this.$axios
          .post('public/banners', {
            category_id: this.selectedCategory.id,
            device: this.$q.platform.is.mobile ? 'mobile' : 'desktop'
          })
          .then(response => {
            if (
              this.settingsStore.banners.map(item => item.id).join(',') !==
              response.data.banners.map(item => item.id).join(',')
            ) {
              this.settingsStore.$patch({
                banners: response.data.banners
              })
            }
          })
      }
    },
    trackView() {
      if (this.$q.localStorage.has('be_cookie_consent')) {
        try {
          const acceptedCookies = this.$q.localStorage.getItem('be_cookie_consent')
          const { trackers } = this.settingsStore
          Object.keys(trackers).forEach(type => {
            if (type === 'gtag' && acceptedCookies.statistically) {
              this.$gtag.event('view_item_list', {
                item_list_id: this.selectedCategory.id,
                item_list_name: this.selectedCategory.name
              })
            } else if (type === 'fbpixel' && acceptedCookies.marketing) {
              fbq('track', 'ViewContent', {
                content_name: this.selectedCategory.name,
                content_category: this.selectedCategory.name,
                content_ids: [this.selectedCategory.id],
                content_type: 'product_group'
              })
            }
          })
          if (acceptedCookies.performance && this.$amplitude !== null) {
            this.$amplitude.track({
              event_type: '[BE] View Category',
              event_properties: {
                categoryId: this.selectedCategory.id,
                categoryName: this.selectedCategory.name,
                companyId: this.settingsStore.website.company_id,
                companyName: this.settingsStore.website.name
              }
            })
          }
        } catch (err) {
          // TODO
        }
      }
    },
    nextCategory() {
      let categoryIndex = this.categories.map(item => item.id).indexOf(this.selectedCategory.id)
      categoryIndex += 1
      if (categoryIndex >= this.categories.length) {
        categoryIndex = 0
      }
      const nextCategory = this.categories[categoryIndex]
      this.$router.push(`/${nextCategory.url}/${nextCategory.id}/${this.menusStore.selected.id}`)
    },
    previousCategory() {
      let categoryIndex = this.categories.map(item => item.id).indexOf(this.selectedCategory.id)
      categoryIndex -= 1
      if (categoryIndex < 0) {
        categoryIndex = this.categories.length - 1
      }
      const nextCategory = this.categories[categoryIndex]
      this.$router.push(`/${nextCategory.url}/${nextCategory.id}/${this.menusStore.selected.id}`)
    },
    handleCategorySwipe({ ...info }) {
      if (this.$q.platform.is.mobile) {
        if (info.direction === 'left') {
          this.nextCategory()
        } else if (info.direction === 'right') {
          this.previousCategory()
        }
      }
    },
    updateFilters(filters) {
      this.openFilters = false
      this.filters = filters
      if (this.search === '' || this.search === null) {
        let translateLanguage = null
        if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
          translateLanguage = this.$q.cookies.get('translate_language')
        }
        this.productsStore.fetch({
          categoryId: this.selectedCategory.id,
          menuId: this.menusStore.selected.id,
          translateLanguage
        })
      } else {
        this.searchProduct()
      }
    },
    updateMenuCategoriesProducts() {
      let translateLanguage = null
      if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
        translateLanguage = this.$q.cookies.get('translate_language')
      }
      this.menusFetch({ translateLanguage }).then(() => {
        if (this.menusStore.items.length > 0) {
          const selectedMenu = this.menusStore.items[0]
          this.menusStore.setSelected(selectedMenu)
          this.categoriesFetch({ menuId: selectedMenu.id, translateLanguage }).then(() => {
            if (this.categories.length > 0) {
              const selectedCategory = this.categories[0]
              this.categoriesSelected(selectedCategory)
              let translateLanguage = null
              if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
                translateLanguage = this.$q.cookies.get('translate_language')
              }
              this.productsStore.fetch({
                categoryId: selectedCategory.id,
                menuId: selectedMenu.id,
                translateLanguage
              })
            }
          })
        }
      })
    },
    updateGeolocation() {
      this.settingsStore.updateShopStatus()
      this.cartStore.storeChanged()
      this.updateMenuCategoriesProducts()
      this.updatePickupDeliveryOptions()
      this.showGeolocationDialog = false
      if (this.currentProductData !== null) {
        this.showChooseDeliveryMethodText = false
        this.orderProduct(this.currentProductData.id, this.viewIndex)
      }
    },
    openGeolocation(deliveryType) {
      if (this.cartStore.group_order) {
        return
      }
      let showGeolocationPopUp = true
      if (this.userLogged) {
        if (deliveryType === 'delivery' && this.userDeliveryAddresses.length > 0) {
          this.defaultDeliveryAddressChanged(this.cartStore.address_id)
          showGeolocationPopUp = false
        }
      }
      if (showGeolocationPopUp) {
        this.geolocationDeliveryType = deliveryType
        this.showGeolocationDialog = true
      } else {
        this.cartStore.delivery_type = deliveryType
        this.updateMenuCategoriesProducts()
        this.updatePickupDeliveryOptions()
        this.settingsStore.updateShopStatus()
      }
    },
    defaultDeliveryAddressChanged(id) {
      const index = this.userDeliveryAddresses.map(item => item.id).indexOf(id)
      if (index !== -1) {
        const address = this.userDeliveryAddresses[index]
        // set the new delivery area
        let storeId = null
        const deliveryAreas = []
        this.settingsStore.localization.delivery_areas.forEach(area => {
          const polygon = []
          const polygonArea = JSON.parse(area.polygon)
          if (Array.isArray(polygonArea[0])) {
            polygonArea[0].forEach(position => {
              polygon.push([position.lat, position.lng])
            })
            deliveryAreas.push({ polygon, info: area })
          }
        })
        deliveryAreas.forEach(area => {
          if (
            storeId === null &&
            classifyPoint(area.polygon, [Number(address.latitude), Number(address.longitude)]) !== 1
          ) {
            storeId = area.info.store_id
            this.cartStore.saveDeliveryArea(area.info)
          }
        })
        if (storeId === null) {
          this.$q.notify({
            message: this.$t('geolocation.no-delivery-to-this-location-has-pickup'),
            position: 'top',
            color: 'negative',
            timeout: 2000
          })
        } else {
          if (this.cartStore.store_id !== storeId) {
            this.cartStore.store_id = storeId
            this.updateMenuCategoriesProducts()
          } else {
            this.cartStore.store_id = storeId
          }
          this.cartStore.address_id = id
          this.cartStore.address = address.address
          this.cartStore.address_line2 = address.address_line2
          this.cartStore.geolocation.lat = address.latitude
          this.cartStore.geolocation.lng = address.longitude
          this.cartStore.informations = address.informations
          if (this.userLogged && this.cartStore.address_id !== null) {
            this.$axios.patch(`public/account/delivery-addresses/${this.cartStore.address_id}/set-default`)
          }
        }
      }
    },
    updatePickupDelivery() {
      if (this.cartStore.delivery_type === 'pickup') {
        this.cartStore.store_id = this.pickupDeliveryId
        this.cartStore.storeChanged()
        this.settingsStore.updateShopStatus()
        this.updateMenuCategoriesProducts()
      } else if (this.cartStore.delivery_type === 'delivery' && this.pickupDeliveryId !== null) {
        if (this.pickupDeliveryId === 0) {
          this.deliveryAddressData = {
            address: null,
            address_line2: '',
            name: '',
            informations: '',
            latitude: null,
            longitude: null,
            id: null
          }
          this.showDeliveryAddress = true
        } else {
          this.defaultDeliveryAddressChanged(this.pickupDeliveryId)
        }
      }
    },
    updatePickupDeliveryOptions() {
      if (this.cartStore.delivery_type === 'delivery') {
        if (this.cartStore.address !== null) {
          if (
            this.cartStore.address_id !== null &&
            this.userDeliveryAddresses.map(item => item.id).indexOf(this.cartStore.address_id) !== -1
          ) {
            this.pickupDeliveryId = this.cartStore.address_id
            this.pickupDeliveryItems = []
          } else {
            this.pickupDeliveryItems = [{ id: null, name: this.cartStore.address }]
            this.pickupDeliveryId = null
          }
          this.userDeliveryAddresses.forEach(address => {
            this.pickupDeliveryItems.push({
              id: address.id,
              name: address.name
            })
          })
          this.pickupDeliveryItems.push({
            id: 0,
            name: this.$t('geolocation.add-address')
          })
        }
      } else {
        this.pickupDeliveryItems = this.settingsStore.stores
        this.pickupDeliveryId = this.cartStore.store_id
      }
    },
    repeatOrder(orderId) {
      if (this.$q.platform.is.mobile) {
        this.$router.push(`/cart?order_id=${orderId}`)
      } else {
        this.$q.loading.show()
        this.$axios
          .get(`public/account/orders/${orderId}?for=reorder`)
          .then(response => {
            this.$q.loading.hide()
            response.data.products.forEach(product => {
              this.cartStore.addProduct(product)
            })
            this.showCart = true
          })
          .catch(() => {
            this.$q.loading.hide()
          })
      }
    },
    orderProductShowGeolocation(currentProduct) {
      this.showChooseDeliveryMethodText = true
      this.closeProductDialog()
      this.currentProductData = currentProduct
      this.openGeolocation(this.cartStore.delivery_type)
    },
    addressAddedUpdated(address) {
      this.setUserDeliveryAddress(address)
      this.cartStore.address_id = address.id
      this.updatePickupDeliveryOptions()
      this.defaultDeliveryAddressChanged(address.id)
      this.showDeliveryAddress = false
    },
    closeAddDeliveryAddress() {
      this.pickupDeliveryId = this.cartStore.address_id
      this.showDeliveryAddress = false
    },
    navigateToMenu() {
      let translateLanguage = null
      if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
        translateLanguage = this.$q.cookies.get('translate_language')
      }
      this.$router.push('/' + this.menusStore.selected.url + '/' + this.menusStore.selected.id)
      this.categoriesFetch({ menuId: this.menusStore.selected.id, translateLanguage }).then(() => {
        if (this.categories.length > 0) {
          const selectedCategory = this.categories[0]
          this.categoriesSelected(selectedCategory)
          this.productsStore.fetch({
            categoryId: selectedCategory.id,
            menuId: this.menusStore.selected.id,
            translateLanguage
          })
        }
      })
    },
    closeProductDialog() {
      if (this.currentUrl !== null) {
        window.history.replaceState({}, null, this.currentUrl)
      }
      this.currentProductData = null
      this.productId = null
    },
    loadNextCategory(index, done) {
      let categoryIndex = this.categories.map(item => item.id).indexOf(this.selectedCategory.id)
      categoryIndex += 1
      if (typeof this.categories[categoryIndex] !== 'undefined') {
        this.showBackToTop = true
        const nextCategory = this.categories[categoryIndex]
        this.categoriesSelected(nextCategory)
        let translateLanguage = null
        if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
          translateLanguage = this.$q.cookies.get('translate_language')
        }
        this.productsStore
          .fetch({
            categoryId: nextCategory.id,
            menuId: this.menusStore.selected.id,
            translateLanguage,
            merge_products: true,
            category_name: nextCategory.name
          })
          .then(() => {
            done()
          })
        if (categoryIndex === this.categories.length - 1) {
          this.$refs.infiniteScrollProducts.stop()
        }
      } else {
        done()
        this.$refs.infiniteScrollProducts.stop()
      }
    },
    backToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.showBackToTop = false
    }
  }
}
</script>
