import { defineStore } from 'pinia'
import { axiosInstance } from 'boot/axios'
import { LocalStorage } from 'quasar'

export const useProductsStore = defineStore('products', {
  state: () => ({
    items: []
  }),
  actions: {
    fetch(filters) {
      let filtersQuery = ''
      if (LocalStorage.has('search_filters')) {
        const searchFilters = LocalStorage.getItem('search_filters')
        if (searchFilters.allergens.length > 0) {
          filtersQuery += `&allergens=${searchFilters.allergens.join(',')}`
        }
      }
      if (filters.translateLanguage !== null) {
        filtersQuery += `&translate_language=${filters.translateLanguage}`
      }

      return axiosInstance
        .get(`public/products?category_id=${filters.categoryId}&menu_id=${filters.menuId}${filtersQuery}`)
        .then(({ data }) => {
          if (filters.merge_products) {
            this.items.push({
              id: 0,
              name: filters.category_name
            })
            this.items = this.items.concat(data.products)
          } else {
            this.items = data.products
          }
        })
        .catch(() => {
          this.items = []
        })
    }
  }
})
